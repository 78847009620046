<template>
  <CustomDrawer
    ref="CustomDrawer"
    :title="title"
    :show-footer="formStatus !== 'detail'"
    @openedCallback="openedCallback"
    @handleConfirm="handleConfirm"
  >
    <CustomForm
      ref="CustomForm"
      :form-model="formModel"
      :form-items="formItem"
      :form-rules="formRules"
    >
      <UploadImg slot="photoIds" slot-scope="{item}" :limit="3" :upload-lists="photoUploadLists" :pic-disable="formStatus === 'detail'" @uploadChange="item.uploadChange" />
      <RegionForm slot="tenant" ref="RegionForm" v-model="formModel.tenant" :disabled="formStatus === 'detail'" />
    </CustomForm>
    <template slot="centerButton" slot-scope="{loading}">
      <el-button type="info" :loading="loading" @click="handleSaveDraft">保存为草稿</el-button>
    </template>
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
import RegionForm from '@/components/RegionForm'
import UploadImg from '@/components/UploadImg'
export default {
  name: 'Form',
  components: { UploadImg, RegionForm, CustomDrawer, CustomForm },
  data() {
    return {
      title: '',
      formId: '',
      formStatus: '',
      dict: {
        volunteerTaskType: []
      },
      photoUploadLists: [],
      formModel: {
        category: '',
        title: '',
        photoIds: '1673993453110947841',
        intro: '',
        tenant: [],
        address: '',
        contactPerson: '',
        contactPhone: '',
        credits: 0,
        claimantsQuantity: 0,
        claimantsTime: [],
        claimantsStartTime: '',
        claimantsEndTime: '',
        time: [],
        startTime: '',
        endTime: '',
        description: '',
        belongs: 2,
        checkInType: 0,
        cancellable: 0
      }
    }
  },
  computed: {
    formItem() {
      return {
        'category': {
          'elColSpan': 12,
          'component': 'CustomFormSelect',
          'label': '分类',
          'dataField': {
            'label': 'dictDesc',
            'value': 'dictPidVal'
          },
          'data': this.dict.volunteerTaskType,
          'disabled': this.formStatus === 'detail'
        },
        'title': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '标题',
          'maxlength': 255,
          'disabled': this.formStatus === 'detail'
        },
        'photoIds': {
          'elColSpan': 24,
          'label': '图片',
          'slot': 'photoIds',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'photoIds', data.join(','))
            } else {
              this.$set(this.formModel, 'photoIds', '')
            }
          }
        },
        'intro': {
          'elColSpan': 24,
          'component': 'CustomFormTextarea',
          'label': '简介',
          'maxlength': 1000,
          'showWordLimit': true,
          'disabled': this.formStatus === 'detail'
        },
        'tenant': {
          'elColSpan': 12,
          'label': '服务区域',
          'slot': 'tenant'
        },
        'address': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '地点',
          'maxlength': 255,
          'disabled': this.formStatus === 'detail'
        },
        'contactPerson': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '联系人',
          'maxlength': 100,
          'disabled': this.formStatus === 'detail'
        },
        'contactPhone': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '联系方式',
          'maxlength': 100,
          'disabled': this.formStatus === 'detail'
        },
        // 'credits': {
        //   'elColSpan': 12,
        //   'component': 'CustomFormNumber',
        //   'label': '奖励积分',
        //   'min': 0,
        //   'max': 99999999,
        //   'precision': 0,
        //   'step': 10,
        //   'controls': true,
        //   'disabled': this.formStatus === 'detail'
        // },
        'claimantsQuantity': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '总报名人数',
          'min': 0,
          'max': 99999999,
          'precision': 0,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'claimantsTime': {
          'elColSpan': 12,
          'component': 'CustomFormDatePicker',
          'label': '报名时间',
          'type': 'datetimerange',
          'valueFormat': 'yyyy-MM-dd HH:mm:ss',
          'disabled': this.formStatus === 'detail'
        },
        'time': {
          'elColSpan': 12,
          'component': 'CustomFormDatePicker',
          'label': '任务时间',
          'type': 'datetimerange',
          'valueFormat': 'yyyy-MM-dd HH:mm:ss',
          'disabled': this.formStatus === 'detail'
        },
        'checkInType': {
          'elColSpan': 24,
          'component': 'CustomFormRadio',
          'label': '签到类型',
          'data': ['活动前', '活动前后'],
          'disabled': this.formStatus === 'detail'
        },
        'description': {
          'elColSpan': 24,
          'component': 'CustomFormTinymce',
          'label': '任务描述',
          'disabled': this.formStatus === 'detail'
        }
      }
    },
    formRules() {
      return {
        category: [
          { required: true, message: '分类不能为空', trigger: ['blur', 'change'] }
        ],
        title: [
          { required: true, message: '标题不能为空', trigger: ['blur', 'change'] }
        ],
        photoIds: [
          { required: true, message: '图片不能为空', trigger: ['blur', 'change'] }
        ],
        tenant: [
          { required: true, message: '服务区域不能为空', trigger: ['blur', 'change'] }
        ],
        address: [
          { required: true, message: '地点不能为空', trigger: ['blur', 'change'] }
        ],
        contactPerson: [
          { required: true, message: '联系人不能为空', trigger: ['blur', 'change'] }
        ],
        contactPhone: [
          { required: true, message: '联系方式不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value && !this.$regex.telRegEx(value)) {
                callback(new Error('联系方式格式不正确'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        // credits: [
        //   { required: true, message: '奖励不能为空', trigger: ['blur', 'change'] }
        // ],
        claimantsQuantity: [
          { required: true, message: '总报名人数不能为空', trigger: ['blur', 'change'] }
        ],
        claimantsTime: [
          { required: true, message: '报名时间不能为空', trigger: ['blur', 'change'] }
        ],
        time: [
          { required: true, message: '任务时间不能为空', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['downloadFile', 'getSysDictServe', 'pubTaskPublicDetail', 'pubTaskAdd', 'pubTaskUpdate']),
    getSysDictList(typeCode) {
      this.getSysDictServe({ typeCode }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.dict[typeCode] = res.data
      })
    },
    handleAdd() {
      this.init('add')
    },
    handleEdit(id) {
      this.init('edit', id)
    },
    handleDetail(id) {
      this.init('detail', id)
    },
    init(formStatus, id = '') {
      Object.assign(this, this.$options.data.call(this))
      this.getSysDictList('volunteerTaskType')
      this.$refs.CustomDrawer.handleOpen()
      this.formId = id
      this.formStatus = formStatus
      switch (formStatus) {
        case 'add':
          this.title = '添加'
          break
        case 'edit':
          this.title = '编辑'
          break
        case 'detail':
          this.title = '查看'
          break
        default:
          this.title = '未知'
          break
      }
    },
    openedCallback() {
      this.initData().then(() => {
        this.$nextTick(() => {
          this.$refs.RegionForm.initRegion()
        })
        this.$refs.CustomForm.$refs.form.clearValidate()
        this.$refs.CustomDrawer.closeLoading()
      }).catch(() => {
        this.$refs.CustomDrawer.handleClose()
      })
    },
    initData() {
      return new Promise((resolve, reject) => {
        if (this.formStatus !== 'add') {
          this.pubTaskPublicDetail({ id: this.formId }).then(res => {
            if (res.code !== 200) {
              this.$message.error(res.msg)
              reject()
              return
            }

            if (res.data.photoIds && res.data.photos) {
              const photoIds = res.data.photoIds.split(',')
              const photos = res.data.photos.split(',')
              photoIds.forEach((item, index) => {
                if (index in photos) {
                  this.photoUploadLists.push({
                    'id': item,
                    'url': photos[index]
                  })
                }
              })
            }

            this.formModel.id = this.formId
            this.formModel.category = res.data.category
            this.formModel.title = res.data.title
            this.formModel.photoIds = res.data.photoIds
            this.formModel.intro = res.data.intro
            this.formModel.tenant = res.data.tenant.split(',').slice(2)
            this.formModel.address = res.data.address
            this.formModel.contactPerson = res.data.contactPerson
            this.formModel.contactPhone = res.data.contactPhone
            this.formModel.credits = res.data.credits
            this.formModel.claimantsQuantity = res.data.claimantsQuantity
            this.formModel.claimantsTime = [res.data.claimantsStartTime, res.data.claimantsEndTime]
            this.formModel.time = [res.data.startTime, res.data.endTime]
            this.formModel.description = res.data.description
            this.formModel.belongs = res.data.belongs
            this.formModel.checkInType = res.data.checkInType
            resolve()
          }).catch(() => {
            reject()
          })
        } else {
          let data = sessionStorage.getItem('VOLUNTEER_TASK_USER')
          if (data) {
            data = JSON.parse(data)
            this.formModel = data.formModel
            this.getFileDetail(data.formModel.photoIds)
          }
          resolve()
        }
      })
    },
    handleConfirm() {
      this.$refs.CustomDrawer.openLoading()
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          const params = { ...this.formModel }
          params.tenant = `1,101,${params.tenant.join(',')}`
          params.claimantsStartTime = params.claimantsTime[0]
          params.claimantsEndTime = params.claimantsTime[1]
          params.startTime = params.time[0]
          params.endTime = params.time[1]
          delete params.claimantsTime
          delete params.time

          switch (this.formStatus) {
            case 'add':
              delete params.id
              this.pubTaskAdd(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('添加成功')
                sessionStorage.removeItem('VOLUNTEER_TASK_USER')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            case 'edit':
              this.pubTaskUpdate(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('修改成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            default:
              this.$refs.CustomDrawer.handleClose()
              break
          }
        } else {
          this.$refs.CustomDrawer.closeLoading()
        }
      })
    },
    handleSaveDraft() {
      this.$refs.CustomDrawer.openLoading()
      sessionStorage.setItem('VOLUNTEER_TASK_USER', JSON.stringify({
        formModel: this.formModel
      }))
      this.$message.success('草稿保存成功')
      this.$refs.CustomDrawer.handleClose()
    },
    getFileDetail(ids) {
      if (!ids) {
        return
      }
      ids = ids.split(',')
      if (ids.length === 0) {
        return
      }
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.photoUploadLists.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
